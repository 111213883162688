@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.category-title {
  font-size: 1.75rem;
}

.current-page-breadcrumb {
  color: $brand-red;
}

.footer-link {
  color: #000;
}

.router-link-active {
  color: #000;
}

.subcategory-title {
  font-size: 1.1rem;
}

.v-breadcrumbs li {
  font-size: 12px;
}
